import Logo from '../assets/Logo.png';
import React from 'react';
import '../styles/style.css';
import '../fonts/Bellerose.ttf';
import '../fonts/Harlow.ttf';
export default function Error() {
    document.title = 'Rugged | 404';
    return (
        <div>
            <center>
                <h1 className="title" style={{marginBottom: 0, color: "white", fontSize: "100px"}}>404</h1>
                <p className="subtitle" style={{marginTop: 0, color: "white", fontSize: "50px"}}>Page not found.</p>
                <a href="/">
                    <img src={Logo} alt="Logo" />
                </a>
            </center>
        </div>
    )
}