import Grid from '@mui/material/Grid';
import Button from '@mui/material/Button';
import Logo from '../assets/Logo.png';
import { BrowserView, MobileView } from 'react-device-detect';

export default function Header() {
    return (
        <div className="header" style={{paddingBottom: "5px"}}>
            <Grid container spacing={1} style={{display: "flex", justifyContent: "center", alignItems: "center", paddingTop: "20px"}}>
                <Grid item xs={4}>
                    <center>
                        <a href="/">
                            <MobileView>
                                <img src={Logo} alt="Logo" style={{minWidth: "40%", width: "130px", maxWidth: "80%"}} />
                            </MobileView>
                            <BrowserView>
                                <img src={Logo} alt="Logo" style={{width: "200px", maxWidth: "50%"}} />
                            </BrowserView>
                        </a>
                    </center>
                </Grid>
                <Grid item xs={4}>
                    <center>
                        <Button 
                        className="headerButton" 
                        variant="outlined"
                        component="a"
                        href="/gallery"
                        >Gallery</Button>
                    </center>
                </Grid>
                <Grid item xs={4}>
                    <center>
                        <Button 
                        className="headerButton" 
                        variant="outlined"
                        component="a"
                        href="/store"
                        >Store</Button>
                    </center>
                </Grid>
                <Grid item xs={12}>
                    <center>
                        <h1 className="title" style={{color: "#ec048c"}}> Designs come to life with Rugged </h1>
                    </center>
                </Grid>
            </Grid>

        </div>
    )
}