import { useEffect } from 'react';
import { BrowserView, MobileView, isBrowser, isMobile } from 'react-device-detect';
import * as THREE from 'three';
import { GLTFLoader } from 'three/examples/jsm/loaders/GLTFLoader.js';
import SceneInit from './lib/SceneInit';
import Rug1 from '../assets/FieldRugFinished.glb';
import Rug2 from '../assets/GhostyFinished.glb';
import Rug3 from '../assets/OFRugFinished.glb';


export default function Rugspin() {
  useEffect(() => {
    const scene = new SceneInit('Scene');
    scene.initialize();
    scene.animate();
    let rug1;
    let rug2;
    let rug3;
    if (isBrowser) {
      const glftLoader1 = new GLTFLoader();
      const glftLoader2 = new GLTFLoader();
      const glftLoader3 = new GLTFLoader();
      glftLoader1.load(Rug1, (gltfScene) => {
        rug1 = gltfScene;
        // gltfScene.scene.rotation.y = Math.PI / 8;
        gltfScene.scene.position.y = -2;
        gltfScene.scene.position.x = -20;
        //rotate the model 90 degrees around the x-axis
        gltfScene.scene.rotation.x = Math.PI / 2.5;
        gltfScene.scene.scale.set(1, 1, 1);
        scene.scene.add(gltfScene.scene);
      });
      glftLoader2.load(Rug2, (gltfScene) => {
          rug2 = gltfScene;
          gltfScene.scene.rotation.y = Math.PI / 8;
          gltfScene.scene.position.y = -2;
          gltfScene.scene.position.x = 0;
          //rotate the model 90 degrees around the x-axis
          gltfScene.scene.rotation.x = Math.PI / 2.5;
          gltfScene.scene.scale.set(2, 2, 2);
          scene.scene.add(gltfScene.scene);
        });
        glftLoader3.load(Rug3, (gltfScene) => {
          rug3 = gltfScene;
          gltfScene.scene.rotation.y = Math.PI / 8;
          gltfScene.scene.position.y = -2;
          gltfScene.scene.position.x = 20;
          gltfScene.scene.rotation.x = Math.PI / 2.5;
          gltfScene.scene.scale.set(2, 2, 2);
          scene.scene.add(gltfScene.scene);
        });
    } else {
      const glftLoader1 = new GLTFLoader();
      glftLoader1.load(Rug1, (gltfScene) => {
        rug1 = gltfScene;
        // gltfScene.scene.rotation.y = Math.PI / 8;
        gltfScene.scene.position.y = 0;
        gltfScene.scene.position.x = 0;
        //rotate the model 90 degrees around the x-axis
        gltfScene.scene.rotation.x = Math.PI / 2.5;
        gltfScene.scene.scale.set(0.75, 0.75, 0.75);
        scene.scene.add(gltfScene.scene);
      });
    }
      
    
    const animate = () => {
      if (rug1) {
        // loadedModel.scene.rotation.x += 0.01;
        rug1.scene.rotation.z += 0.01;
        // rug1.scene.rotation.y += -0.001;
        // loadedModel.scene.rotation.z += 0.01;
      }
      if (rug2) {
        // loadedModel.scene.rotation.x += 0.01;
        rug2.scene.rotation.z += 0.02;
        // loadedModel.scene.rotation.z += 0.01;
      }
      if (rug3) {
        // loadedModel.scene.rotation.x += 0.01;
        rug3.scene.rotation.z += 0.03;
        // loadedModel.scene.rotation.z += 0.01;
      }
      requestAnimationFrame(animate);
    };
    animate();
  }, []);

  return (
    <div>
      <canvas id="Scene" />
    </div>
  );
}