import React from 'react';
import Header from '../components/Header.tsx';
import Rugspin from '../components/Rugspin.tsx';

export default function MainPage() {
    document.title = 'Rugged | Home';
    return (
        <div>
            <Header />
            <Rugspin />
            <div className="mainContent">
                <center>
                <h1 className="title" style={{ color: "#ec048c" }}>Rugged Custom Rugs</h1>
                <p className="subtitle" style={{paddingBottom: "20px"}}>
                    Your rug, your design, your way.
                    <br />
                    <br />
                    Transform your space with our custom rugs, tailored specifically for you.
                    <br />
                    From size and material, to color and pattern, every detail counts and can be customized to your liking.
                    <br />
                    All with a 100% satisfaction guarantee.
                    <br />
                    <br />
                    View our gallery of already delivered rugs to get inspired, or contact us on our store page to order your own.
                </p>
                </center>
            </div>
        </div>
    );
}
