import React from 'react';
import Header from '../components/Header.tsx';
import S3 from 'aws-sdk/clients/s3.js';
import PhotoAlbum from "react-photo-album";

const s3 = new S3({
    endpoint: `https://ed1fb0af83a83ca59c749ddffd118906.r2.cloudflarestorage.com`,
    accessKeyId: process.env.REACT_APP_ACCESSKEYID,
    secretAccessKey: process.env.REACT_APP_SECRETACCESSKEY,
    signatureVersion: 'v4',
});
  
export default function Gallery() {
    document.title = 'Rugged | Gallery';
    
    const [images, setImages] = React.useState([]);
    const [loaded, setLoaded] = React.useState(false);
    React.useEffect(() => {
        s3.listObjects({ Bucket: 'rugged-business' }, (err, data) => {
            data.Contents.map((item) => {
                let img = new Image();
                img.src = "https://images.rugged.business/" + encodeURIComponent(item.Key)
                img.onload = () => {
                    setImages(images => [...images, { src: "https://images.rugged.business/" + encodeURIComponent(item.Key), width: img.width, height: img.height }])
                }
                setLoaded(true)
                
            })
        })
    }, [])
    if (loaded) {
        const headerItem = document.getElementsByClassName('header')[0]
        document.body.style.marginTop = headerItem.clientHeight + 30 + 'px'
    }
    if (loaded) {
        return (
            <>
                <Header />
                <PhotoAlbum layout="masonry" photos={images} />
            </>
        )
    } else {
        return (
            <>
                <Header />
            </>
        )
    }
}