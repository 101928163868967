import {
  BrowserRouter as Router,
  Routes,
  Route,
  Link
} from "react-router-dom";

import MainPage from './pages/MainPage.tsx';
import Error from './pages/Error.tsx';
import Upload from './pages/Upload.tsx';
import Gallery from './pages/Gallery.tsx'
import Store from './pages/Store.tsx'


export default function App() {
  return (
    <Router>
      <div>
        <Routes>
          <Route path="/" element={ <MainPage/> } />
          <Route path="/upload" element={ <Upload/> } />
          <Route path="/gallery" element={ <Gallery/> } />
          <Route path="/store" element={ <Store/> } />
          <Route path="*" element={ <Error/> } />
        </Routes>
      </div>
    </Router>
  );
}

