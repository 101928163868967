import { Widget } from '@typeform/embed-react'
import React from 'react'
import Header from '../components/Header.tsx';

export default function Store() {
    document.title = 'Rugged | Store';
    const [loaded, setLoaded] = React.useState(false);
    return (
        <>
            <div>
                <div>
                <Widget id="Q487NuxB" style={{height: "100vh"}}/>
                <center>
                    <h1 className="title" style={{color: "white", fontFamily: "bellerose"}}>You can contact us at <a style={{ color: "#ec048c"}} href="mailto:contact@rugged.business">contact@rugged.business</a></h1>
                </center>
                </div>
            </div>
        </>
    )
}