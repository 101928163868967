import React, { useState } from 'react';
import AWS from 'aws-sdk'
import Avatar from '@mui/material/Avatar';
import Button from '@mui/material/Button';
import CssBaseline from '@mui/material/CssBaseline';
import TextField from '@mui/material/TextField';
import Box from '@mui/material/Box';
import LockOutlinedIcon from '@mui/icons-material/LockOutlined';
import Typography from '@mui/material/Typography';
import Container from '@mui/material/Container';
import { createTheme, ThemeProvider } from '@mui/material/styles';

const S3_BUCKET ='rugged-business';
const REGION ='auto';
AWS.config.update({
    endpoint: `https://ed1fb0af83a83ca59c749ddffd118906.r2.cloudflarestorage.com/rugged-business`,
    accessKeyId: process.env.REACT_APP_ACCESSKEYID,
    secretAccessKey: process.env.REACT_APP_SECRETACCESSKEY,
})
const myBucket = new AWS.S3({
    params: { Bucket: S3_BUCKET},
    region: REGION,
})

const theme = createTheme({
    palette: {
        primary: {
            main: '#e6ed59',
        },
        secondary: {
            main: '#19857b',
        },
        error: {
            main: '#f44336',
        },
    },
});


export default function Upload() {
    document.title = 'Rugged | Upload';
    const [progress , setProgress] = useState(0);
    const [selectedFile, setSelectedFile] = useState(null);
    const [loggedin, setLoggedin] = useState(false);
    const handleFileInput = (e) => {
        setSelectedFile(e.target.files[0]);
    }
    const uploadFile = (file) => {
        const params = {
            ACL: 'public-read',
            Body: file,
            Bucket: S3_BUCKET,
            Key: file.name
        };
        myBucket.putObject(params)
            .on('httpUploadProgress', (evt) => {
                setProgress(Math.round((evt.loaded / evt.total) * 100))
            })
            .send((err) => {
                if (err) console.log(err)
            })
    }
    const handleSubmit = (event: React.FormEvent<HTMLFormElement>) => {
        event.preventDefault();
        const data = new FormData(event.currentTarget);
        if (data.get('email') === process.env.REACT_APP_USERNAME && data.get('password') === process.env.REACT_APP_PASSWORD) {
          setLoggedin(true);
        } else {
            alert("Incorrect username or password");
        }
    };
    if (loggedin) {
        return (
            <div>
                <h1>Upload</h1>
                <p>Progress: {progress}%</p>
                <input type="file" onChange={handleFileInput}/>
                <button onClick={() => uploadFile(selectedFile)}> Upload to Database</button>
            </div>
        )
    } else {
        return (
            <ThemeProvider theme={theme}>
                <Container component="main" maxWidth="xs">
                    <CssBaseline />
                    <Box
                    sx={{
                        marginTop: 8,
                        display: 'flex',
                        flexDirection: 'column',
                        alignItems: 'center',
                    }}
                    >
                    <Avatar sx={{ m: 1, bgcolor: 'primary.main' }}>
                        <LockOutlinedIcon />
                    </Avatar>
                    <Typography component="h1" variant="h5">
                        Sign in
                    </Typography>
                    <Box component="form" onSubmit={handleSubmit} noValidate sx={{ mt: 1 }}>
                        <TextField
                        margin="normal"
                        required
                        fullWidth
                        id="email"
                        label="Username"
                        name="email"
                        autoComplete="email"
                        autoFocus
                        variant="filled"
                        />
                        <TextField
                        margin="normal"
                        required
                        fullWidth
                        name="password"
                        label="Password"
                        type="password"
                        id="password"
                        variant="filled"
                        autoComplete="current-password"
                        />
                        <Button
                        type="submit"
                        fullWidth
                        variant="contained"
                        color="primary"
                        sx={{ mt: 3, mb: 2 }}
                        >
                        Sign In
                        </Button>
                    </Box>
                    </Box>
                </Container>
                </ThemeProvider>
        )
    }
}
